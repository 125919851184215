<template>
  <div
    class="fill-height"
    :class="$vuetify.breakpoint.mdAndUp ? 'pa-3' : 'pa-0'"
  >
    <v-row class="fill-height">
      <v-col
        cols="12"
        lg="12"
        md="12"
        :class="!$vuetify.breakpoint.mdAndUp ? 'pr-1' : ''"
        v-if="changeSubscriptionFlag"
      >
        <OrderSummaryCompany
          :company-info="companyInfo"
          :payment-history="paymentHistory"
          @updateCompanyData="updateCompanyData"
        />
      </v-col>
      <v-col
        cols="12"
        lg="12"
        md="12"
        class="d-flex justify-center align-center"
        height="600"
        :style="$vuetify.breakpoint.smAndDown ? 'margin-left: calc(25vw)' : ''"
        v-else
      >
        <v-progress-circular
          v-if="showLoader"
          indeterminate
          :width="5"
          :size="80"
          color="green"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserDataVue from "../../../mixins/UserData.vue";
import OrderSummaryCompany from "../../molecules/orderSummaryCompany.vue";
export default {
  name: "Subscription",
  components: {
    OrderSummaryCompany,
  },
  mixins: [UserDataVue],
  props: {},
  data() {
    return {
      changeSubscriptionFlag: false,
      paymentHistory: [],
      companyInfo: {},
      showLoader: true,
    };
  },
  computed: {
    companyInformation() {
      return this.$store.state.Company.company;
    },
  },
  watch: {
    async companyInformation() {
      if (this.companyInformation.company_id) {
        await this.updateCompanyData();
      }
    },
  },
  async created() {
    if (
      Object.keys(this.$store.state.Subscription.subscriptionProducts)
        .length === 0
    )
      await this.fetchProductsData();
    this.subscriptionData = {};
    if (this.companyInformation.company_id) {
      const CompanyData = await this.$store.dispatch("getPurchaseHistoryById", {
        companyId: this.companyInformation.company_id,
      });
      CompanyData.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      this.changeSubscriptionFlag = false;
      this.subscriptionData = Object.assign({}, CompanyData[0]);
      this.subscriptionData = Object.assign(
        this.subscriptionData,
        this.$store.state.Subscription.subscriptionMap[
          this.subscriptionData.subscription_id
        ],
      );
      this.companyInformation.subscription = this.subscriptionData;
      this.paymentHistory = CompanyData;
      this.companyInfo = this.companyInformation;
      if (this.paymentHistory)
        this.$nextTick(() => {
          this.showLoader = false;
          this.changeSubscriptionFlag = true;
        });
    }
  },
  methods: {
    async fetchProductsData() {
      const subscriptionData = await this.$store.dispatch(
        "getSubscriptionProductsData",
      );
      if (subscriptionData.status !== "error") {
        let subscriptionObject = {};
        subscriptionData.subscriptions.forEach((element) => {
          let products = {};
          let storage = [];
          subscriptionData.products.forEach((item) => {
            let price;
            price = subscriptionData.prices.find((obj) => {
              return obj.id == item.default_price;
            });
            item.price = price;
            if (item.id === element.products.baseProduct) {
              products.baseProduct = item;
            }
            if (item.id === element.products.addOn) {
              products.addOn = item;
            }
            if (item.id === element.products.center) {
              products.center = item;
            }
            if (
              element.products.storage &&
              element.products.storage.includes(item.id)
            ) {
              storage.push(item);
            }
          });
          if (storage.length) {
            products.storage = storage;
          }
          if (Object.keys(products).length) {
            element.productsData = products;
          }
          subscriptionObject[element.subscription_id] = element;
        });
        this.$store.commit("setSubscriptionMap", subscriptionObject);
        this.$store.commit("setSubscriptionProduct", subscriptionData);
        console.log(this.$store.state.Subscription.subscriptionProducts);
      }
    },
    async updateCompanyData() {
      this.subscriptionData = {};
      const companyId = this.companyInformation.company_id
        ? this.companyInformation.company_id
        : this.$store.state.Company.company.company_id;
      const purchaseHistory = await this.$store.dispatch(
        "getPurchaseHistoryById",
        {
          companyId: companyId,
        }
      );
      purchaseHistory?.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      this.changeSubscriptionFlag = false;
      this.subscriptionData = Object.assign({}, purchaseHistory[0]);
      this.subscriptionData = Object.assign(
        this.subscriptionData,
        this.$store.state.Subscription.subscriptionMap[
          this.subscriptionData.subscription_id
        ],
      );
      this.companyInformation.subscription = this.subscriptionData;
      this.paymentHistory = purchaseHistory;
      this.companyInfo = this.companyInformation;
      this.changeSubscriptionFlag = true;
    },
    upgradeSubscription(subscriptionData) {
      this.companyInformation.subscription = subscriptionData;
      this.changeSubscriptionFlag = true;
    },
    cancelUpgradeSubscription() {
      this.changeSubscriptionFlag = false;
      this.$nextTick(() => {
        this.changeSubscriptionFlag = true;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
