var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fill-height",
      class: _vm.$vuetify.breakpoint.mdAndUp ? "pa-3" : "pa-0",
    },
    [
      _c(
        "v-row",
        { staticClass: "fill-height" },
        [
          _vm.changeSubscriptionFlag
            ? _c(
                "v-col",
                {
                  class: !_vm.$vuetify.breakpoint.mdAndUp ? "pr-1" : "",
                  attrs: { cols: "12", lg: "12", md: "12" },
                },
                [
                  _c("OrderSummaryCompany", {
                    attrs: {
                      "company-info": _vm.companyInfo,
                      "payment-history": _vm.paymentHistory,
                    },
                    on: { updateCompanyData: _vm.updateCompanyData },
                  }),
                ],
                1
              )
            : _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  style: _vm.$vuetify.breakpoint.smAndDown
                    ? "margin-left: calc(25vw)"
                    : "",
                  attrs: { cols: "12", lg: "12", md: "12", height: "600" },
                },
                [
                  _vm.showLoader
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: "",
                          width: 5,
                          size: 80,
                          color: "green",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }